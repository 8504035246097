import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Etapas from '../components/Etapas'
import { WebApi } from '../services'
import { ReactComponent as Calendar } from '../assets/svg/calendar.svg'
import { ReactComponent as Soporte } from '../assets/svg/soporte.svg'
import moment from 'moment'

const StatusPage = () => {
  const [data, setData] = useState<{ shipping: any, sHistory: any[], paso: number, loading: boolean, noregister:boolean }>({ sHistory: [], paso: 0, shipping: null, loading: false, noregister: false })
  const param = useParams()

  // const getShipping = () => {
  //   setData({ ...data, loading: true })
  //   WebApi.getShipping(param.id_order_platform).then((response) => {
  //     getShippingHistory(response.item)
  //     console.log(response.item)
  //   }).catch((error) => {
  //     console.log(error)
  //     setData({ ...data, loading: false })
  //   })
  // }

  const getShippingHistory = (shipping? : any) => {
    setData({ ...data, loading: true, noregister: false })
    WebApi.getShippingHistory(param.id_order_platform).then((response) => {
      console.log(response)
      // eslint-disable-next-line no-debugger
      debugger
      if (response.message !== 'No existen registros para esta orden') {
        checkPaso(response.data.statuses, response.data)
      } else {
        setData({ ...data, loading: false, noregister: true })
      }
    })
  }

  const checkPaso = (arrItems : any[], shipping : any) => {
    let _paso = 0
    if (arrItems.some((item : any) => item.generic_status === 0 || item.generic_status === 13 || item.generic_status === 14)) { _paso = 0 }
    if (arrItems.some((item : any) => item.generic_status === 15)) { _paso = 1 }
    if (arrItems.some((item : any) => item.generic_status === 9 || item.generic_status === 10 || item.generic_status === 17)) { _paso = 2 }
    if (arrItems.some((item : any) => item.generic_status === 1)) { _paso = 3 }
    if (arrItems.some((item : any) => item.generic_status === 2)) { _paso = 4 }
    setData({ ...data, paso: _paso, sHistory: arrItems, shipping, loading: false })
  }

  useEffect(() => {
    getShippingHistory()
    return () => setData({ sHistory: [], paso: 0, shipping: null, loading: false, noregister: false })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param.id_order_platform])

  return (
    <section className='position-relative'>
      <div className='container-xxl px-0'>
        <div className='wrap-estados shadow-sm'>
          <div className='container-fluid py-5'>
            <div className='row justify-content-center'>
              <div className='col-12 col-lg-10'>
                <div className='py-5'>
                  {
                    (data.loading) && <div className='text-center'><div className='spinner-border text-secondary' role='status'><span className='visually-hidden'>Cargando...</span> </div></div>
                  }
                  {data.noregister && !data.loading && <p className='h4 text-primary fw-bold'>No existe información sobre este pedido, por favor contactar a Servicio de atención a cliente</p>}
                  {data.shipping !== null && <Etapas paso={data.paso} />}
                </div>
              </div>
            </div>
            <div className='row justify-content-around'>
              <div className='col-12 col-md-5'>
                <h4 className='fw-bold border-bottom pb-2'>Historial de estados</h4>
                {data.shipping !== null && data.shipping.statuses.length && data.shipping.statuses.filter((item: any) => item.generic_status === 2).length > 0 && <p className='text-dark mb-1 fw-bold'>Entregado</p>}
                <ul className='list-unstyled'>
                  {
                    data.shipping !== null && data.shipping.statuses.filter((item: any) => item.generic_status === 2).sort((a: any, b : any) => b.id - a.id).map((item : any, idx: number) => {
                      return (
                        <li key={idx}>
                          <div className='d-flex justify-content-between'>
                            <p className='text-dark small m-0 w-50'>{item.courier_status}</p>
                            <p className='text-dark small m-0 d-flex align-items-center w-50'><Calendar className='me-1' /> <span>{moment(item.created_at).format('DD-MM-YYYY hh:mm:ss')}</span></p>
                          </div>
                        </li>
                      )
                    })
                  }
                </ul>
                {data.shipping !== null && data.shipping.statuses.filter((item: any) => item.generic_status === 1).length > 0 && <p className='text-dark mb-1 fw-bold'>En reparto</p>}
                <ul className='list-unstyled'>
                  {
                    data.shipping !== null && data.shipping.statuses.filter((item: any) => item.generic_status === 1).sort((a: any, b : any) => b.id - a.id).map((item : any, idx: number) => {
                      return (
                        <li key={idx}>
                          <div className='d-flex justify-content-between'>
                            <p className='text-dark small m-0 w-50'>{item.courier_status}</p>
                            <p className='text-dark small m-0 d-flex align-items-center w-50'><Calendar className='me-1' /> <span>{moment(item.created_at).format('DD-MM-YYYY hh:mm:ss')}</span></p>
                          </div>
                        </li>
                      )
                    })
                  }
                </ul>
                {data.shipping !== null && data.shipping.statuses.filter((item: any) => item.generic_status === 15 || item.generic_status === 9 || item.generic_status === 10 || item.generic_status === 17).length > 0 && <p className='text-dark mb-1 fw-bold'>Retirado por operador</p>}
                <ul className='list-unstyled'>
                  {
                    data.shipping !== null && data.shipping.statuses.filter((item: any) => item.generic_status === 15 || item.generic_status === 9 || item.generic_status === 10 || item.generic_status === 17).sort((a: any, b : any) => b.id - a.id).map((item : any, idx: number) => {
                      return (
                        <li key={idx}>
                          <div className='d-flex justify-content-between'>
                            <p className='text-dark small m-0 w-50'>{item.courier_status}</p>
                            <p className='text-dark small m-0 d-flex align-items-center w-50'><Calendar className='me-1' /> <span>{moment(item.created_at).format('DD-MM-YYYY hh:mm:ss')}</span></p>
                          </div>
                        </li>
                      )
                    })
                  }
                </ul>
                {data.shipping !== null && data.shipping.statuses.filter((item: any) => item.generic_status === 0 || item.generic_status === 13 || item.generic_status === 14).length > 0 && <p className='text-dark mb-1 fw-bold'>Orden en preparación</p>}
                <ul className='list-unstyled'>
                  {
                    data.shipping !== null && data.shipping.statuses.filter((item: any) => item.generic_status === 0 || item.generic_status === 13 || item.generic_status === 14).sort((a: any, b : any) => b.id - a.id).map((item : any, idx: number) => {
                      return (
                        <li key={idx}>
                          <div className='d-flex justify-content-between'>
                            <p className='text-dark small m-0 w-50'>{item.courier_status}</p>
                            <p className='text-dark small m-0 d-flex align-items-center w-50'><Calendar className='me-1' /> <span>{moment(item.created_at).format('DD-MM-YYYY hh:mm:ss')}</span></p>
                          </div>
                        </li>
                      )
                    })
                  }
                </ul>
              </div>
              <div className='col-12 col-md-5 mt-4 mt-md-0'>
                <h4 className='fw-bold border-bottom pb-2'>Datos del envío</h4>
                {data.shipping && <p className='text-dark'>Distribuido por: <strong>{data.shipping.courier}</strong></p>}
                {data.shipping && <p className='text-dark'>N° de Orden: <strong>{param.id_order_platform}</strong></p>}
                {/* data.shipping && data.shipping.dead_line_date !== '' && <p className='text-dark'>Fecha estimada de entrega: <strong>{data.shipping.dead_line_date}</strong></p> */}

                <p className='small'>Si necesitas información acerca de tu envío, comunícate con nosotros haciendo click en el siguiente botón:</p>
                <div className='text-center text-md-start'><a href='https://recursos.reuse.cl/centro-de-ayuda' className='btn btn-primary px-4 lh-1 fw-bold'><Soporte className='me-2' style={{ width: '18px' }} /> Servicio al cliente</a></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  )
}

export default StatusPage
