import React, { useState } from 'react'
import { Search, ExclamationTriangleFill } from 'react-bootstrap-icons'
import { useNavigate, useParams } from 'react-router-dom'

const Buscador = () => {
  const param = useParams()
  const [data, setData] = useState<{ valid: boolean, q: string, error: string }>({ valid: false, q: param.id_order_platform === undefined ? '' : String(param.id_order_platform), error: '' })
  const navigate = useNavigate()

  const handleSearch = () => {
    setData({ ...data, error: '' })
    console.log(isValid())
    if (isValid()) {
      navigate('/status/' + data.q)
    }
  }

  const isValid = () => {
    const ret = true
    if (!/[0-9]/.test(data.q)) {
      setData({ ...data, error: 'El numero de orden es inválido' })
      return false
    }
    if (!parseInt(data.q)) {
      setData({ ...data, error: 'El numero de orden es inválido' })
      return false
    }
    return ret
  }

  return (
    <div>
      <div className='input-group input-group-lg mb-3'>
        <input
          type='search'
          value={data.q}
          onPaste={(e) => {
            e.preventDefault()
            return false
          }}
          onKeyDown={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault()
            }
            if (event.key === 'Enter') {
              handleSearch()
            }
          }} onChange={(e) => setData({ ...data, q: e.target.value })} className='form-control' placeholder='Ingrese su N° de orden' aria-label='N° de orden' aria-describedby='button-search'
        />
        <button className='btn btn-dark' type='button' id='button-addon2' onClick={handleSearch}><Search /></button>
      </div>
      {
        data.error !== '' &&
          <div className='alert alert-danger d-flex align-items-center' role='alert'>
            <ExclamationTriangleFill className='me-3' />
            <div className='small'>
              {data.error}
            </div>
          </div>
      }
    </div>
  )
}

export default Buscador
