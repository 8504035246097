import React from 'react'
import { Outlet } from 'react-router-dom'
import Buscador from '../Buscador'
import Footer from './Footer'
import Header from './Header'
import delivery from '../../assets/img/delivery.png'

interface OwnProps {
    addMenu?: boolean
}

const Layout : React.FC<OwnProps> = ({ addMenu }) => {
  return (
    <div className='wrapper d-flex flex-column h-100'>
      <Header />
      <main className='flex-fill position-relative'>
        <section className='pt-5 bg-main pb-5 pb-md-0'>
          <div className='container-xxl'>
            <div className='row justify-content-center'>
              <div className='col-11 col-md-6 d-flex flex-column justify-content-center'>
                <h1 className='text-dark fw-bolder'>¿Quiéres saber en que etapa esta <span className='text-primary'>tu compra ?</span></h1>
                <p>Solo tienes que poner tu número de compra en la siguiente casilla y asi sabrás en que etapa esta tu compra a ser entregada.</p>
                <img src={delivery} alt='Reuse.cl' className='delivery d-block d-md-none' />
                <div className='my-md-4'><Buscador /></div>
              </div>
              <div className='col-11 col-md-6 d-flex justify-content-center'>
                <img src={delivery} alt='Reuse.cl' className='delivery d-none d-md-block' />
              </div>
            </div>
          </div>
        </section>
        <Outlet />
      </main>
      <Footer />
    </div>
  )
}

export default Layout
