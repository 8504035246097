import React from 'react'
import { ReactComponent as Moto } from '../../assets/svg/moto.svg'
import { ReactComponent as Check } from '../../assets/svg/check.svg'
interface OwnProps {
    paso: number
}

const Etapas : React.FC<OwnProps> = ({ paso }) => {
  return (
    <div className='etapas'>
      <div className={(paso === 4) ? 'etapas-wrapper entregado d-flex' : 'etapas-wrapper d-flex'}>
        <div className={(paso === 0) ? 'etapa active-moto' : (paso > 0) ? 'etapa active' : 'etapa'}>
          <div className='moto'><Moto /></div>
          <div className='circulo' />
          <div className='circulo-active' />
          <span className='text-dark text-center'>Preparando tu pedido!</span>
        </div>
        <div className={(paso === 1) ? 'etapa active-moto' : (paso > 1) ? 'etapa active' : 'etapa'}>
          <div className='moto'><Moto /></div>
          <div className='circulo' />
          <div className='circulo-active' />
          <span className='text-dark text-center'>Retirado por el courrier</span>
        </div>
        <div className={(paso === 2) ? 'etapa active-moto' : (paso > 2) ? 'etapa active' : 'etapa'}>
          <div className='moto'><Moto /></div>
          <div className='circulo' />
          <div className='circulo-active' />
          <span className='text-dark text-center'>Pedido ya lo tiene el courrier!</span>
        </div>
        <div className={(paso === 3) ? 'etapa active-moto' : (paso > 3) ? 'etapa active' : 'etapa'}>
          <div className='moto'><Moto /></div>
          <div className='circulo' />
          <div className='circulo-active' />
          <span className='text-dark text-center'>Pedido va en camino!</span>
        </div>
        <div className={(paso === 4) ? 'etapa active-moto' : (paso > 4) ? 'etapa active' : 'etapa'}>
          <div className='moto'><Check /></div>
          <div className='circulo' />
          <div className='circulo-active' />
          <span className='text-dark text-center'>Pedido entregado!</span>
        </div>
      </div>
    </div>
  )
}

export default Etapas
